export const formatPrice = (price) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(price);
};

const urlData = 'https://suilly-bo.arscenic.info/items/cartes_restauration?fields[]=*&fields[]=produits.produits_evenement_id.*.*&fields[]=id_evenement.*';
const urlFiles = 'https://suilly-bo.arscenic.info/assets/';
const FoodData = async () => {
  let foodData = localStorage.getItem("foodData");
  if (foodData === null) {
    foodData = await fetch(urlData)
      .then(response => {
        return response.json()
      })
      .then(data => {
        const carte = data.data.filter((item) => item.statut === 'publie');
        console.log(carte);
        if (carte.length === 0) {
          return [];
        }
        const produits = carte[0].produits.map((item) => {
          const newItem = {
            price: item.produits_evenement_id.prix,
            name: item.produits_evenement_id.id_produit_base.titre,
            img: item.produits_evenement_id.image.id ? urlFiles+item.produits_evenement_id.image.id : '',
            section: item.produits_evenement_id.categorie.titre,
          }
          return newItem;
        });
        localStorage.setItem("foodData", JSON.stringify(produits));
        console.log(produits);
        return produits;
      }).catch((error) => {console.log(error)})
  } else {
    foodData = JSON.parse(foodData);
  }
  console.log(foodData);
  return foodData;
};


const FoodItems = async () => {
  const data = await FoodData();
    return data.reduce((res, food) => {
    if (!res[food.section]) {
      res[food.section] = [];
    }
    res[food.section].push(food);
    return res;
  }, {});
}

export { FoodItems };
