import React, { useState, useEffect } from 'react';

import styled from "styled-components";

import { FoodItems } from "../../data/FoodData";
import { FoodGrid, Food, FoodLabel } from "./FoodGrid";
import { formatPrice } from "./../../data/FoodData";

const StyledMenu = styled.div`
  height: 100%;
  width: 67%;
  margin: 65px 0 0 20px;
  background: #fff;
  padding: 5px 20px 20px 20px;
`;

const Menu = ({ orders, setOrders }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function getItems() {
      
      const data = await FoodItems();
      setItems(data);
    };
    getItems();
  }, []);

  return (
    <StyledMenu className="menu">
      {Object.entries(items).map(([sectionName, foods]) => (
        <>
          <h1>{sectionName}</h1>
          <FoodGrid>
            {foods.map((food) => (
              <Food img={food.img} onClick={() => setOrders([...orders, food])}>
                <FoodLabel>
                  <div>{food.name}</div>
                  <div>{formatPrice(food.price)}</div>
                </FoodLabel>
              </Food>
            ))}
          </FoodGrid>
        </>
      ))}
    </StyledMenu>
  );
};

export default Menu;
