import React, { useState } from "react";
import styled from "styled-components";
import { ConfirmButton, DialogFooter } from "../FoodDialog/FoodDialog";
import { formatPrice } from "./../../data/FoodData";
import { getPrice } from "./../FoodDialog/FoodDialog";

const StyledCart = styled.div`
  position: fixed;
  right: 0;
  top: 65px;
  width: 28%;
  background: white;
  height: calc(100% - 80px);
  z-index: 10;
  display: flex;
  flex-direction: column;
`;

const OrderContent = styled.div`
  padding: 20px;
  height: 100%;
  overflow: auto;
`;

const OrderContainer = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid grey;
`;

const OrderItem = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: repeat(4, 10% 50% 20% 1fr);
  justify-content: space-between;
`;

const ItemDetail = styled.div`
  color: grey;
  font-size: 10px;
`;

const Cart = ({ orders, setOrders, setOpenFood }) => {
  const [isOpened, setIsOpened] = useState(false);
  const getOrderTotal = orders.reduce((total, orderItem) => {
    return total + getPrice(orderItem);
  }, 0);

  const total = getOrderTotal;

  const deleteItem = (index) => {
    const newOrders = [...orders];
    newOrders.splice(index, 1);
    setOrders(newOrders);
  };

  let cartClass = "cart";
  if (isOpened) {
    cartClass += " opened";
  }
  return (
    <StyledCart className={cartClass}>
      {orders.length === 0 ? (
        <OrderContent>Le panier est vide.</OrderContent>
      ) : (
        <OrderContent className="order-content">
          <OrderContainer className="header" onClick={(e) => setIsOpened(!isOpened)}>Dans le panier : {orders.length}{orders.length  > 1 ? ' éléments' : ' élément'} - {formatPrice(total)}</OrderContainer>
          {orders.map((item, idx) => (
            <OrderContainer key={item} className="list-products">
              <OrderItem
                style={{ cursor: "pointer" }}
                className="order-item"
                onClick={() => setOpenFood({ ...item, index: idx })}
              >
                <div>{idx + 1}</div>
                <div>
                  {item.name}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteItem(idx);
                  }}
                >
                  <span>❌</span>
                </div>
                <div>{formatPrice(getPrice(item))}</div>
              </OrderItem>
            </OrderContainer>
          ))}
          <OrderContainer className="list-products">
            <OrderItem>
              <div>Total</div>
              <div></div>
              <div></div>
              <div>{formatPrice(total)}</div>
            </OrderItem>
          </OrderContainer>
        </OrderContent>
      )}

      <DialogFooter>
        <ConfirmButton onClick={() => setOrders([])}>Commande payée</ConfirmButton>
      </DialogFooter>
    </StyledCart>
  );
};

export default Cart;
