import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    background: #f2f2f2!important;
    color: #000!important;
    height: 100%!important;
    font-family: 'Open Sans', sans-serif!important;
  }
  html {
    height: 100%!important;
  }
  h1,h2,h3 {
    font-family: 'Exo', sans-serif!important;
  }
  
  @media (max-width: 769px) {
    .menu {
      width: calc(100% - 40px)!important;
      margin:0!important;
      padding-top:80px!important;
      padding-bottom:150px!important;
    }
    .cart {
      width: 100%!important;
      bottom:0!important;
      height:auto!important;
      top:auto!important;
      box-shadow: 0px 0px .5em grey!important;
    }
    .list-products {
      display:none!important;
    }
    .cart.opened .list-products {
      display:block!important;
    }
    .order-content {
      height:auto!important;
      max-height: 60vh!important;
    }
  }
`;

export { GlobalStyles };
